import React from 'react';
import debounce from 'lodash.debounce';

import QuickWinSelector from './fields/quick_win_selector';
import StatusSelector from './fields/status_selector';
import HelpWantedSelector from './fields/help_wanted_selector';
import CustomersSelector from './fields/customers_selector';
import TagsSelector from './fields/tags_selector';
import OpportunityValueInput from './fields/opportunity_value_input';
import OpportunityValueCurrencySelector from './fields/opportunity_value_currency_selector';
import FeatureUrlInput from './fields/feature_url_input';

const stringEllipis = string => string && string.length > 27
  ? string.substring(0, 24) + '...'
  : string;

const mapFieldsToAttributes = {
  statusOption: 'status',
  isQuickWinOption: 'quick_win',
  isBrokenWindowOption: 'broken_window',
  isHelpWantedOption: 'help_wanted',
  isWatcherOption: 'is_watcher',
  customerTags: 'tag_list',
  teamTags: 'tag_list',
  assetTags: 'tag_list',
  opportunityValue: 'opportunity_value',
  opportunityValueCurrencyOption: 'opportunity_value_currency',
  existingFeaturePath: 'existing_feature_path',
  channelLink: 'channel_links_attributes',
  isSafetyIssueOption: 'safety_issue',
  isMaintenanceOption: 'maintenance',
  isResourceRequestOption: 'resource_request',
  isAlertOption: 'alert',
}

const handleValueFromRadioButton = value =>
  handleStringToBool(value);

const handleStringToBool = value => {
 if (value && typeof value === 'string') {
    if (value.toLowerCase() === 'true') return true;
    if (value.toLowerCase() === 'false') return false;
 }
 return value;
};

const tagsByType = ({ channel, type }) => {
  return channel && channel.tags
    .filter(tag => tag.tag_type === type)
    .map(tag => ({
      label: tag.name,
      value: tag.name,
    })
  );
};

const tagOptions = ({ tags }) => {
  return tags.map(tag => ({
    label: tag.name,
    value: tag.name,
  })) || [];
};

class FormFields extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      statusOptions: this.props.channel?.status || 'ready',
      isQuickWinOption: this.props.channel?.quick_win || false,
      isBrokenWindowOption: this.props.channel?.broken_window || false,
      isHelpWantedOption: this.props.channel?.help_wanted || false,
      opportunityValue: undefined,
      opportunityValueCurrencyOption:
        this.props.channel?.opportunity_value_currency || undefined,
      existingFeaturePath: this.props.channel?.existing_feature_path || undefined,
      channelLinks: this.props.channel && this.props.channel.channel_links || [],
      linksCounter: (this.props.channel?.channel_links || []).length || 0,
      isWatcherOption: this.props.channel?.is_watcher || false,
      isAlertOption: this.props.channel?.alert || false,
      isSafetyIssueOption: this.props.channel?.safety_issue || false,
      isMaintenanceOption: this.props.channel?.maintenance || false,
      isResourceRequestOption: this.props.channel?.resource_request || false,
    };
    this.handleTagsFilterFetch = this.handleTagsFilterFetch.bind(this);
  }

  async componentDidMount() {
    const { isEducationOrganizationPersona } = this.props;

    await this.props.fetchTags({ tagType: 'team' });
    await this.props.fetchTags({ tagType: 'asset' });

    if (!isEducationOrganizationPersona) {
      await this.props.fetchTags({ tagType: 'customer' });
    }
  }

  debounceSearch = debounce((input, tagType) => this.handleTagsFilterFetch({ input, tagType }), 300);

  handleTagsSearch = ({ input, tagType }) => {
    this.debounceSearch(input, tagType);
  }

  debounceUpdateOpportunityValue = debounce((field, value) => this.handleNormalUpdate({ field, value }), 500);

  handleUpdateOpportunityValue = (event) => {
    const value = event.floatValue
      ? event.floatValue
      : event.target && event.target.value
      ? event.target.value
      : event.value;

    const field = 'opportunityValue';

    this.debounceUpdateOpportunityValue(field, value);
  }

  handleTagsFilterFetch = async ({ input, tagType }) => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    await this.props.fetchTags({ search: input, tagType });
  }

  updateTags(options, meta, field) {
    const { channel } = this.props;

    if (
      field === 'customerTags' ||
      field === 'teamTags' ||
      field === 'assetTags'
    ) {

      const tag_type = field.split('Tags')[0];
      const unchangedTags = channel.tags
        .filter(tag => tag.tag_type !== tag_type)
        .map(tag => ({
          name: tag.name,
          tag_type: tag.tag_type,
        }));

      const values = (options || []).map(opt => ({
        name: opt.label,
        tag_type,
      }));

      const mergedValues = unchangedTags.concat(values);

      this.setState({
        [field]: values,
      });
      this.props.updateChannelMetadataField({
        id: channel.id,
        channel: {
          [mapFieldsToAttributes[field]]: mergedValues
        }
      });
    }
  }

  handleNormalUpdate({ field, value }) {
    const { channel } = this.props;

    this.setState({
      [field]: handleValueFromRadioButton(value),
    });

    this.props.updateChannelMetadataField({
      id: channel.id,
      channel: {
        [mapFieldsToAttributes[field]]: handleStringToBool(value),
      }
    });
  };

  update(field) {
    const { channel } = this.props;

    return (e) => {
      if (
        field === 'statusOption' ||
        field === 'isQuickWinOption' ||
        field === 'opportunityValue' ||
        field === 'opportunityValueCurrencyOption' ||
        field === 'existingFeaturePath' ||
        field === 'isBrokenWindowOption' ||
        field === 'isHelpWantedOption' ||
        field === 'isWatcherOption' ||
        field === 'isAlertOption' ||
        field === 'isSafetyIssueOption' ||
        field === 'isMaintenanceOption' ||
        field === 'isResourceRequestOption'
      ) {
        const value = e.floatValue
          ? e.floatValue
          : e.target && e.target.value
          ? e.target.value
          : e.value; // note; a legimate value can be `false`

        this.handleNormalUpdate({ field, value });
      }

      return null;
    }
  };

  updateChannelLink({ field, _destroy, id }) {
    const { channel } = this.props;

    return (e) => {
      if (field === 'channelLink') {
        const value = e.floatValue
          ? e.floatValue
          : e.target && e.target.value
          ? e.target.value
          : e.value; // note; a legimate value can be `false`

        this.props.updateChannelMetadataField({
          id: channel.id,
          channel: {
            [mapFieldsToAttributes[field]]: [{
              url: value,
              ...(_destroy && { _destroy }),
              ...(id && { id }),
            }],
          }
        });
      }

      return null;
    }
  };

  handleAddLinkField() {
    const count = this.state.linksCounter + 1;
    this.setState({ linksCounter: count });
  }

  render() {
    const {
      currentUser,
      channel,
      isEducationOrganizationPersona,
      organizationPersona,
    } = this.props;

    const {
      statusOptions,
      isQuickWinOption,
      isAlertOption,
      isSafetyIssueOption,
      isMaintenanceOption,
      isResourceRequestOption,
      isBrokenWindowOption,
      isHelpWantedOption,
      isWatcherOption,
      opportunityValue,
      opportunityValueCurrencyOption,
      existingFeaturePath,
      channelLinks,
      linksCounter,
    } = this.state;

    const customerTagOptions = tagOptions({ tags: this.props.customerTags });

    const teamTagOptions = tagOptions({ tags: this.props.teamTags });

    const assetTagOptions = tagOptions({ tags: this.props.assetTags });

    const customerTags = tagsByType({ channel, type: 'customer' });

    const teamTags = tagsByType({ channel, type: 'team' });

    const assetTags = tagsByType({ channel, type: 'asset' });

    return channel ? (
      <div className="channel-metadata-form-fields">
        <div className="field-container">
          <label>Status</label>
          <StatusSelector
            key="statusOption"
            onChange={this.update('statusOption')}
            value={statusOptions}
            defaultValue={channel.status || statusOptions}
            name="statusSelector"
            organizationPersona={organizationPersona}
            className="select-field status-selector"
          />
        </div>
        <div className="field-container">
          <label>Watch this request?</label>
          <QuickWinSelector
            key="isWatcherOption"
            onChange={this.update('isWatcherOption')}
            value={isWatcherOption}
            defaultValue={channel.is_watcher ||isWatcherOption}
            name="watcherSelector"
            className="select-field watcher-selector"
          />
        </div>
        {!isEducationOrganizationPersona && (
          <div className="field-container">
            <label>Customers</label>
            <CustomersSelector
              onChange={(opt, meta) =>
                this.updateTags(opt, meta, 'customerTags')
              }
              onInputChange={(input) => this.handleTagsSearch({ input, tagType: 'customer' })}
              value={customerTagOptions}
              options={customerTagOptions}
              defaultValue={customerTags}
              name="customersSelector"
              className="select-field customers-selector"
              placeholder="None"
            />
          </div>
        )}
        <div className="field-container">
          <label>Assigned teams</label>
          <TagsSelector
            onChange={(opt, meta) =>
              this.updateTags(opt, meta, 'teamTags')
            }
            onInputChange={(input) => this.handleTagsSearch({ input, tagType: 'team' })}
            value={teamTagOptions}
            options={teamTagOptions}
            defaultValue={teamTags}
            // isCreatable={false}
            name="tagsSelector"
            className="select-field teams-selector"
            placeholder="None"
          />
        </div>
        <div className="field-container">
          <label>Related features and assets</label>
          <TagsSelector
            onChange={(opt, meta) =>
              this.updateTags(opt, meta, 'assetTags')
            }
            onInputChange={(input) => this.handleTagsSearch({ input, tagType: 'asset' })}
            value={assetTagOptions}
            options={assetTagOptions}
            defaultValue={assetTags}
            name="assetsSelector"
            className="select-field assets-selector"
            placeholder="None"
          />
        </div>
        {!isEducationOrganizationPersona && (
          <>
            <div className="field-container">
              <label>Opportunity value</label>
              <OpportunityValueInput
                onChange={this.handleUpdateOpportunityValue}
                value={opportunityValue}
                defaultValue={channel.opportunity_value || opportunityValue}
                name="opportunityValueInput"
                className="input-field opportunity-value-input"
                placeholder="Input Currency Value"
              />
            </div>
            <div className="field-container">
              <label>Currency</label>
              <OpportunityValueCurrencySelector
                key="opportunityValueCurrencyOption"
                onChange={this.update('opportunityValueCurrencyOption')}
                value={opportunityValueCurrencyOption}
                defaultValue={channel.opportunity_value_currency || opportunityValueCurrencyOption}
                name="opportunityValueCurrencySelector"
                className="select-field opportunity-value-currency-selector"
              />
            </div>
          </>
        )}
        {!isEducationOrganizationPersona && (
          <div className="field-container">
            <label>Quick win?</label>
            <QuickWinSelector
              key="isQuickWinOption"
              onChange={this.update('isQuickWinOption')}
              value={isQuickWinOption}
              defaultValue={channel.quick_win || isQuickWinOption}
              name="quickWinSelector"
              className="select-field quick-win-selector"
            />
          </div>
        )}
        {isEducationOrganizationPersona && (
          <div className="field-container">
            <label>Safety Issue?</label>
            <QuickWinSelector
              key="isSafetyIssueOption"
              onChange={this.update('isSafetyIssueOption')}
              value={isSafetyIssueOption}
              defaultValue={channel.safety_issue || isSafetyIssueOption}
              name="safetyIssueSelector"
              className="select-field safety-issue-selector"
            />
          </div>
        )}
        {isEducationOrganizationPersona && (
          <div className="field-container">
            <label>Alert?</label>
            <QuickWinSelector
              key="isAlertOption"
              onChange={this.update('isAlertOption')}
              value={isAlertOption}
              defaultValue={channel.alert || isAlertOption}
              name="alertSelector"
              className="select-field alert-selector"
            />
          </div>
        )}
        {isEducationOrganizationPersona && (
          <div className="field-container">
            <label>Resource request?</label>
            <QuickWinSelector
              key="isResourceRequestOption"
              onChange={this.update('isResourceRequestOption')}
              value={isResourceRequestOption}
              defaultValue={channel.resource_request || isResourceRequestOption}
              name="resourceRequestSelector"
              className="select-field resource-request-selector"
            />
          </div>
        )}
        {!isEducationOrganizationPersona && (
          <div className="field-container">
            <label>Help wanted?</label>
            <QuickWinSelector
              key="isHelpWantedOption"
              onChange={this.update('isHelpWantedOption')}
              value={isHelpWantedOption}
              defaultValue={channel.help_wanted || isHelpWantedOption}
              name="helpWantedSelector"
              className="select-field help-wanted-selector"
            />
          </div>
        )}
        {isEducationOrganizationPersona && (
          <div className="field-container">
            <label>Maintenance?</label>
            <QuickWinSelector
              key="isMaintenanceOption"
              onChange={this.update('isMaintenanceOption')}
              value={isHelpWantedOption}
              defaultValue={channel.maintenance || isMaintenanceOption}
              name="maintenanceSelector"
              className="select-field maintenance-selector"
            />
          </div>
        )}

        {!isEducationOrganizationPersona && (
          <div className="field-container">
            <label>Existing feature url</label>
            <FeatureUrlInput
              onChange={this.update('existingFeaturePath')}
              value={existingFeaturePath}
              defaultValue={channel.existing_feature_path || existingFeaturePath}
              name="featureUrlInput"
              className="input-field feature-url-input"
              placeholder="https://www.example.url/feature"
            />
          </div>
        )}
        {!isEducationOrganizationPersona && (
          <div className="field-container">
            <label>Broken window?</label>
            <QuickWinSelector
              key="isBrokenWindowOption"
              onChange={this.update('isBrokenWindowOption')}
              value={isBrokenWindowOption}
              defaultValue={channel.broken_window || isBrokenWindowOption}
              name="brokenWindowSelector"
              className="select-field broken-window-selector"
            />
          </div>
        )}

        {!isEducationOrganizationPersona && (
          <div className="field-container">
            <label>Broken window?</label>
            <QuickWinSelector
              key="isBrokenWindowOption"
              onChange={this.update('isBrokenWindowOption')}
              value={isBrokenWindowOption}
              defaultValue={channel.broken_window || isBrokenWindowOption}
              name="brokenWindowSelector"
              className="select-field broken-window-selector"
            />
          </div>
        )}
        {/*
          <div className="field-container">
            <div className="links-label">
              <label>Links</label>
              <i
                onClick={() => this.handleAddLinkField()}
                className="links-label-icon fa-plus"
              >
              </i>
            </div>
            {Array.from(Array(linksCounter || 1)).map((c, index) => {
              return (
                <div key={index} className="link-container">
                  <div className="link-type">
                    Jira
                  </div>
                  <FeatureUrlInput
                    onChange={this.updateChannelLink({ field: 'channelLink' })}
                    value={handleLinkUrlValue(channelLinks[index])}
                    defaultValue={
                      handleLinkUrlValue(channel.channel_links[index] || channelLinks[index])
                    }
                    name="url"
                    className="input-field feature-url-input channel-link-input"
                    placeholder="https://www.jira.url"
                  />
                  <div
                    onClick={this.updateChannelLink({
                      field: 'channelLink',
                      _destroy: true,
                      id: channelLinkByIndex(channelLinks[index]).id,
                    })}
                    className="delete link">
                    X
                  </div>
                </div>
              )
            })}
          </div>
        */}
      </div>
    ) : null;
  }
}

export default FormFields;

const handleLinkUrlValue = link => {
  if (!link) {
    return undefined;
  }
  return link.url;
};

const channelLinkByIndex = link => {
  if (!link) {
    return { id: undefined };
  }
  return link;
}
