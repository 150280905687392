import React from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import moment from 'moment';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import TableComponent from '../../../components/common/TableComponent';
import AddUserPane from '../../../components/pane/AddUserPane';
import {
  getUsers,
  getUsersCsv,
  removeUser,
  removeUsers,
  updateAllUsers,
} from '../../../services/organization';

import {
  permittedRolesList,
  sleep,
  toastifyConfiguration,
} from '../../../components/util/helpers';
import BulkAddUsersForm from './bulk_add_users_form';

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 25;

toast.configure();

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: 1, // props.organizationUsers.total_pages,
      isPaneOpen: false,
      isPaneOpenLeft: false,
      isAddMode: false,
      isEditMode: false,
      selectedUser: {},
      search: '',
      users: [], // props.organizationUsers.entries,
      usersCount: 0, // props.organizationUsers.total_count,
      isCsvActionInProgress: false,
      selection: [],
    };
    this.handleOnClickAddUser = this.handleOnClickAddUser.bind(this);
    this.onAddUserPaneClose = this.onAddUserPaneClose.bind(this);
    this.handleOnClickEditUser = this.handleOnClickEditUser.bind(this);
    this.handleOnClickDisableMultipleUsers = this.handleOnClickDisableMultipleUsers.bind(this);
    this.handleOnClickRemoveUser = this.handleOnClickRemoveUser.bind(this);
    this.handleOnClickRemoveMultipleUsers = this.handleOnClickRemoveMultipleUsers.bind(this);
    this.handleUsersSearch = this.handleUsersSearch.bind(this);
    this.handleUsersFilterFetch = this.handleUsersFilterFetch.bind(this);
    this.handleGetPaginatedUsers = this.handleGetPaginatedUsers.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleOpenBulkAddUsers = this.handleOpenBulkAddUsers.bind(this);
    this.handleOnFetchData = this.handleOnFetchData.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement(this.el);
  }

  debounceSearch = debounce(input => this.handleUsersFilterFetch(input), 500);

  handleUsersSearch = event => {
    event && event.preventDefault();
    const { search } = this.state;
    const input = event?.target?.value;

    // Note: validate no previous "search" value exists, to enable clear search
    if (!input && !search) {
      return;
    }

    this.debounceSearch(input);
  }

  handleUsersFilterFetch = async input => {
    const { currentUser } = this.props;
    const filteredUsers = await this.props.fetchUsers({ search: input });
    if (filteredUsers) {
      this.setState({ users: filteredUsers.entries || [], search: input });
    }
  }

  handleGetPaginatedUsers = async (page, pageSize, sorted) => {
    const { currentUser } = this.props;
    const { search } = this.state;
    await this.props.fetchUsers({
      page: page + 1,
      pageSize,
      search,
      sort: sorted,
    });
    this.setState({ loading: false });
  }

  handleOnClickAddUser = () => {
    this.setState({ isPaneOpen: true, isEditMode: false, selectedUser: {} });
  }

  onAddUserPaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  handleOnClickEditUser = user => {
    this.setState({ isAddMode: false, isEditMode: true, isPaneOpen: true, selectedUser: user });
  }

  handleConfirmDelete = async (user, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveUser}
            subtitle="You want to delete this user?"
            itemToRemove={user}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOpenBulkAddUsers = async (event) => {
    const {
      canAddWorkspaces,
      currentUser,
      currentLocale,
      isCurrentUserAccountAdmin,
      isCurrentUserWorkspaceManager,
      workspacesAvailable,
    } = this.props;

    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <BulkAddUsersForm
            canAddWorkspaces={canAddWorkspaces}
            organizationId={currentUser?.organization_id}
            currentUser={currentUser}
            currentLocale={currentLocale}
            isCurrentUserAccountAdmin={isCurrentUserAccountAdmin}
            isCurrentUserWorkspaceManager={isCurrentUserWorkspaceManager}
            onClose={onClose}
            handleGetPaginatedUsers={this.handleGetPaginatedUsers}
            defaultPage={DEFAULT_PAGE}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            workspacesAvailable={workspacesAvailable}
          />
        );
      }
    });
  }

  handleOnClickRemoveUser = async (user, event) => {
    event && event.preventDefault();
    const { currentUser } = this.props;
    const response = await removeUser({
      organization_id: currentUser?.organization_id,
      user_id: user.id,
    });
    if (!(response || {}).error) {
      await this.handleGetPaginatedUsers(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
      toast.success('User removed successfully!', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the user. Please try again.', toastifyConfiguration({}));
    }
  }

  handleOnClickRemoveMultipleUsers = async (userIds, event) => {
    event && event.preventDefault();
    const { currentUser } = this.props;

    if (userIds?.length > 0) {
      const response = await removeUsers({
        organization_id: currentUser?.organization_id,
        user_ids: userIds,
      });
      if (!(response || {}).error) {
        await this.handleGetPaginatedUsers(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
        toast.success('User(s) removed successfully!', toastifyConfiguration({}));
      } else {
        toast.error('Error removing the user(s). Please try again.', toastifyConfiguration({}));
      }
    } else {
      return;
    }
  }

  handleOnClickDisableMultipleUsers = async (userIds, event) => {
    event && event.preventDefault();
    const { currentUser } = this.props;

    if (userIds?.length > 0) {
      const response = await updateAllUsers({
        organization_id: currentUser?.organization_id,
        user_ids: userIds,
        disabled: true,
      });
      if (!(response || {}).error) {
        await this.handleGetPaginatedUsers(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
        toast.success('User(s) disabled successfully!', toastifyConfiguration({}));
      } else {
        toast.error('Error disabling the user(s). Please try again.', toastifyConfiguration({}));
      }
    } else {
      return;
    }
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser } = this.props;
    await getUsersCsv({ organization_id: currentUser?.organization_id });
    await sleep(300);
    this.setState({ isCsvActionInProgress: false });
  }

  handleOnFetchData({ state }) {
    this.setState({ loading: true });
    this.handleGetPaginatedUsers(state.page, state.pageSize, state.sorted);
  }

  handleSelection({ selection }) {
    this.setState({ selection });
  }

  render() {

    const {
      adminCount,
      canAddWorkspaces,
      currentLocale,
      currentUser,
      isBulkAddUsersEnabled,
      isCurrentUserAccountAdmin,
      isCurrentUserWorkspaceManager,
      organizationUsers,
      organizationTeams,
      pages,
      users,
      usersCount,
      workspacesAvailable,
      workspaceManagerCount,
    } = this.props;

    const normalUserCount = usersCount - (adminCount + workspaceManagerCount);

    const {
      isAddMode,
      isEditMode,
      isCsvActionInProgress,
      selectedUser,
    } = this.state;

    const columns = [{
      id: 'user',
      Header: 'User',
      sortable: false,
      accessor: u => u.first_name ? `${u.first_name} ${u.last_name}` : '',
    }, {
      accessor: 'email',
      Header: 'Email',
    }, {
      id: 'last_sign_in_at', // use this for sort and db column recognition
      Header: 'Last activity',
      accessor: u =>
        <span className='user-last-login'>{u.last_sign_in_at_was ? moment(u.last_sign_in_at_was).utc().format('YYYY-MM-DD, h:mm:ss a') : 'no activity'}</span> // Custom cell components!
    }, {
      id: 'created_at',
      Header: 'Created at',
      accessor: u =>
        <span className='user-created-at'>{moment(u.created_at).utc().format('YYYY-MM-DD')}</span>
    }, {
      id: 'roles',
      Header: 'Roles',
      sortable: false,
      accessor: u => <span className='user-roles'>{u.roles.map(r => r.name).join(', ')}</span>
    }, {
      id: 'action',
      Header: 'Action',
      sortable: false,
      accessor: u => (
        <div>
          <span className='edit-cell' onClick={this.handleOnClickEditUser.bind(this, u)}>
            <i className="bi bi-pencil-square"></i>
          </span>
          <span className='remove-cell' onClick={this.handleConfirmDelete.bind(this, u)}>
            <i className="bi bi-trash3"></i>
          </span>
        </div>
      )
    }];

    return (
      <div className="mt-5" ref={ref => this.el = ref}>
        <div className="row mb-4 resource-metadata">
          <div className="col-md-4 column-width people">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="h5">Account admin</div>
                  <div className="d-flex">
                    <i className="bi bi-person me-1"></i>
                    <div className="card-count">{adminCount}</div>
                  </div>
                </div>
                <div>
                  Full account access
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 column-width people">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="h5">Workspace manager</div>
                  <div className="d-flex">
                    <i className="bi bi-person me-1"></i>
                    <div className="card-count">{workspaceManagerCount}</div>
                  </div>
                </div>
                <div>
                  Workspace management access
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 column-width people">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="h5">User</div>
                  <div className="d-flex">
                    <i className="bi bi-person me-1"></i>
                    <div className="card-count">{normalUserCount}</div>
                  </div>
                </div>
                <div>
                  Restricted access
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2 add-resource-column-width">
            <div className="card">
              <div className="card-body d-flex justify-content-center align-items-center">
                <div onClick={this.handleOnClickAddUser} className="add-resource-action">
                  + Add user
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="filter-actions-and-export d-flex justify-content-between">
          <div className="search">
            <input
              type="text"
              className="form-control search-input"
              onChange={this.handleUsersSearch}
              placeholder="Search users"
            />
          </div>
          <div className="ms-2 button-actions">
            {isCsvActionInProgress ? (
              <button className="export export-csv people" type="button" disabled>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                <span>Exporting...</span>
              </button>
            ) : (
              <Button
                handleClick={() => {
                  this.setState({ isCsvActionInProgress: true });
                  this.handleCsvExport();
                }}
                label="Export CSV"
                classes="export export-csv"
              />
            )}
          </div>
        </div>
        <div className="container-fluid table-details-section">
          <TableComponent
            columns={columns}
            data={users}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            deleteConfirmationSubtitle="You want to delete the user(s)?"
            disableConfirmationSubtitle="You want to disable the user(s)?"
            loading={this.state.loading}
            pages={pages}
            handleOnFetchData={this.handleOnFetchData}
            handleSelection={this.handleSelection}
            handleOnClickDisableMultipleUsers={this.handleOnClickDisableMultipleUsers}
            handleOnClickRemoveMultipleItems={this.handleOnClickRemoveMultipleUsers}
            multipleDeletionEnabled={isCurrentUserAccountAdmin}
            multipleDisableEnabled={isCurrentUserAccountAdmin}
          />
          <SlidingPane
            className='add-user-pane'
            overlayClassName='sliding-pane-overlay'
            isOpen={ this.state.isPaneOpen }
            title={isEditMode ? 'Edit User': 'Add New User'}
            hideHeader
            width='90%'
            subtitle=''
            onRequestClose={this.onAddUserPaneClose}>
              <AddUserPane
                handleGetPaginatedUsers={this.handleGetPaginatedUsers}
                currentUser={currentUser}
                onAddUserPaneClose={this.onAddUserPaneClose}
                organizationTeams={organizationTeams.entries}
                permittedRoles={permittedRolesList}
                isEditMode={isEditMode}
                selectedUser={selectedUser}
                canAddWorkspaces={canAddWorkspaces}
                workspacesAvailable={workspacesAvailable}
              />
          </SlidingPane>
        </div>
      </div>
    );
  }
}

Content.defaultProps = {
 keyField: 'id',
};

Content.propTypes = {};

export default Content;
