import React, { Fragment } from 'react';

import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DevicesImage from 'images/devices.png';
import ChartHistogramWhiteImage from 'images/chart_histogram_white.svg';
import ChartHistogramBlackImage from 'images/chart_histogram_black.svg';
import DashboardSquareBlackImage from 'images/dashboard_square_black.svg';
import DashboardSquareWhiteImage from 'images/dashboard_square_white.svg';
import GlobalEducationWhiteImage from 'images/global_education_white.svg';
import GlobalEducationBlackImage from 'images/global_education_black.svg';

import withRouter from '../../../util/with_router';

import DashboardWidgetsWrapper from './dashboard_widgets_wrapper';

import HorizontalNavigation from '../../main/horizontal_navigation/horizontal_navigation_container';
import AboutDashboardPageDrawer from '../../AboutDrawers/DashboardPage/AboutPageDrawer';
import GeneralUpgradePlanModal from '../../my_settings/pricing_plans/general_upgrade_plan_modal';
import ActivationWizardModal  from '../../ActivationWizardModal/activation_wizard_modal';

import { workspaceIdQs } from '../../util/helpers';
import { getCountForChannels } from '../../../services/channel_api';
import { paramsToQueryString } from '../../../services/util/helpers';

const DASHBOARD_TITLES = [
  'Your key to sales enablement!',
  'The relentless pursuit of your customers!',
  'Everyone can understand the problems your customers are facing!',
  'Default to action and default to transparency!'
];

const DASHBOARD_SUBTITLES = [
  'Quickly digest what your customers care about',
  'Problem solve where it matters',
  'Enabling delivery teams to fix problems and improve the product',
  'Delight customers, close new deals, and increase renewals',
];

const randomElement = array => array[Math.floor(Math.random() * array.length)];

const randomTitle = randomElement(DASHBOARD_TITLES);
const randomSubtitle = randomElement(DASHBOARD_SUBTITLES);

const tabPath = ({ currentLocale, dashboard, useDemoData, location }) => {
  const { search } = location;
  const workspaceId = workspaceIdQs(search);
  const queryParams = {
    ...(workspaceId && { workspace_id: workspaceId }),
    ...(dashboard && { dashboard: dashboard }),
  };

  const queryString = paramsToQueryString(queryParams);

  if (useDemoData) {
    return queryString
      ? `/#/${currentLocale}/dashboard-onboarding-demo?${queryString}`
      : `/#/${currentLocale}/dashboard-onboarding-demo`;
  }

  return queryString
    ? `/#/${currentLocale}?${queryString}`
    : `/#/${currentLocale}`;
};


const isActive = (paramValue, dashboard) =>
  paramValue?.toLowerCase() === dashboard;

const isActiveClass = (paramValue, dashboard) =>
  isActive(paramValue, dashboard) ? 'active' : '';

class HomeDashboard extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      currentWorkspaceId:
        workspaceIdQs(this.props.location.search) ||
        this.props.lastVisitedWorkspace,
      isAboutPageDrawerOpen: false,
      isWorkspacesLoading: true,
    };
    this.handleWorkspaceClick = this.handleWorkspaceClick.bind(this);
    this.onOpenAboutPagerDrawerClose = this.onOpenAboutPagerDrawerClose.bind(this);
    this.handleGeneralUpgradePlanModal = this.handleGeneralUpgradePlanModal.bind(this);
    this.handleActivationWizardModal = this.handleActivationWizardModal.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    Modal.setAppElement(this.el);
    const { currentUser, workspaces } = this.props;
    const { currentWorkspaceId } = this.state;
    const organizationId = currentUser?.organization_id;
    // NOTE: fetch workspaces if not present. In event user refreshes browser
    if (
      !workspaces ||
      (workspaces && workspaces.length === 0)
    ) {
      const response = await this.props.fetchWorkspaces();

      if (!(response || {}).error) {
        this.setState({ isWorkspacesLoading: false });
      }

      this.setState({ isWorkspacesLoading: false });

    } else {
      this.setState({ isWorkspacesLoading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleWorkspaceClick({ workspaceId }) {
    const { dashboardType } = this.props;

    dashboardType
      ? this.props.navigate(`?workspace_id=${workspaceId}&dashboard=${dashboardType}`)
      : this.props.navigate(`?workspace_id=${workspaceId}`);
    this.setState({ currentWorkspaceId: workspaceId });
  }

  handleOnClickOpenAboutPageDrawer() {
    this.setState({ isAboutPageDrawerOpen: true });
  }

  onOpenAboutPagerDrawerClose() {
    this.setState({ isAboutPageDrawerOpen: false });
  }

  handleGeneralUpgradePlanModal = async ({}) => {
    const {
      currentUser,
      currentLocale,
      defaultWorkspaceId,
      isFreeTrialAccount,
      openModal,
    } = this.props;

    const { currentWorkspaceId } = this.state;

    const workspaceId = currentWorkspaceId || defaultWorkspaceId;

    if (!isFreeTrialAccount) {
      return openModal('new_public_channel', { currentUser, currentLocale, workspaceId });
    }

    const organizationId = currentUser?.organization_id;
    const response = await getCountForChannels({
      organizationId,
      workspaceId,
    });

    const count = response?.total_count;

    if (isFreeTrialAccount && count > 100) {
      return confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <GeneralUpgradePlanModal
              onClose={onClose}
              navigate={this.props.navigate}
            />
          );
        }
      });
    }

    return openModal('new_public_channel', { currentLocale, currentUser, workspaceId });
  }

  handleActivationWizardModal = async () => {
    const {
      createChannelInActivationWizard,
      currentUser,
      currentLocale,
      defaultWorkspaceId,
      isCurrentUserAccountAdmin,
      isCurrentUserWorkspaceManager,
      isEducationOrganizationPersona,
    } = this.props;

    const { currentWorkspaceId } = this.state;

    const workspaceId = currentWorkspaceId || defaultWorkspaceId;

    return confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ActivationWizardModal
            onClose={onClose}
            navigate={this.props.navigate}
            workspaceId={workspaceId}
            currentUser={currentUser}
            currentLocale={currentLocale}
            isCurrentUserAccountAdmin={isCurrentUserAccountAdmin}
            isCurrentUserWorkspaceManager={isCurrentUserWorkspaceManager}
            isEducationOrganizationPersona={isEducationOrganizationPersona}
          />
        );
      }
    });

  }

  handleUpdateToggleWatchState = () => {
    this.setState({ isToggleForWatchers: !this.state.isToggleForWatchers });
  }

  render() {

    const {
      currentLocale,
      currentUser,
      dashboardType,
      defaultWorkspaceId,
      isCurrentUserAccountAdmin,
      isCurrentUserWorkspaceManager,
      isEducationOrganizationPersona,
      isFreeTrialAccount,
      lastVisitedChannel,
      lastVisitedWorkspace,
      location,
      openModal,
      useDemoData,
      workspaces,
    } = this.props;

    const {
      currentWorkspaceId,
      // isToggleForWatchers,
      isWorkspacesLoading,
    } = this.state;

    const {
      active_invitations_count,
      active_requests_to_join_my_org_count,
      default_workspace_id,
    } = currentUser;

    const { search } = location;
    const queryParams = {
      ...(currentWorkspaceId && { workspace_id: currentWorkspaceId}),
      ...(dashboardType && { dashboard: dashboardType }),
    };

    const queryString = paramsToQueryString(queryParams);


    const workspaceList = (workspaces || []).map(((workspace, i) => {
      const isSelectedWorkspace =
        workspace.id.toString() === (currentWorkspaceId || default_workspace_id);
      const listClassName = isSelectedWorkspace
        ? 'workspace-item-container selected'
        : 'workspace-item-container';

      return (
        <div
          key={i}
          className={listClassName}
          onClick={() => this.handleWorkspaceClick({
            workspaceId: workspace.id,
          })}
        >
          <div className="workspace-title">
            <div className="title">
              {workspace.name}
            </div>
          </div>
        </div>
      );
    }), this);

    const workspaceId = currentWorkspaceId || defaultWorkspaceId;
    const workspace = workspaces.find(workspace =>
      workspace.id === (currentWorkspaceId || defaultWorkspaceId)
    );

    const defaultChannelId = (workspace || {}).first_channel_id;
    const demoClassName = useDemoData ? 'home-dashboard-demo' : '';

    const workspaceHasChannels = workspace?.has_non_system_channels;

    return (
      <div
        className={`main contactimmed-main ${demoClassName} container-fluid home-dashboard px-0`}
        ref={ref => this.el = ref}
      >
        <HorizontalNavigation currentUser={currentUser} currentLocale={currentLocale} />
        <div className={`dashboard container-fluid dashboard-section ${demoClassName} py-3 px-3`}>
          <div className="dashboard-workspaces">
            <div className="section-container">
              <div className="section-title">Dashboard</div>
              <div
                className="pro-tip-button"
                onClick={() => this.handleOnClickOpenAboutPageDrawer()}
              >
                Pro Tip
              </div>
            </div>
            {isWorkspacesLoading ? (
              <div className="workspaces-list-loading-spinner-container">
                <Spinner animation="border" role="status" variant="secondary" />
              </div>
            ) : (
              <Fragment>
                {active_invitations_count ? (
                  <div className="workspace-item-container">
                    <div className="workspace-title">
                      <div className="title">
                        <span className="icon-container">
                          <i className="bi bi-bell invite-alert-icon me-1"></i>
                        </span>
                        <a target="_blank" href={`/#/${currentLocale}/settings/my-invitations`}>
                          Invite to join an org
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null}
                {active_requests_to_join_my_org_count &&
                  (isCurrentUserAccountAdmin || isCurrentUserWorkspaceManager) ? (
                  <div className="workspace-item-container">
                    <div className="workspace-title">
                      <div className="title">
                        <span className="icon-container">
                          <i className="bi bi-bell invite-alert-icon me-1"></i>
                        </span>
                        <a target="_blank" href={`/#/${currentLocale}/settings/invitations?status=accepted`}>
                          Requests to join my org
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null}
                {workspaceList}
              </Fragment>
            )}
          </div>
          {isWorkspacesLoading ? (
            <div className="dashboard-widgets vh-100 d-flex justify-content-center container-fluid align-items-center">
              <Spinner animation="border" role="status" variant="secondary" />
            </div>
          ) : (workspaceHasChannels || useDemoData) ? (
            <Container fluid className="dashboard-widgets py-5 pt-4">
              <Row className="mb-1">
                <Col className="ms-3">
                  {useDemoData && (
                    <div className="mb-1 sample-dashboard-header-alert">
                      !Demo dashboard with sample data!
                    </div>
                  )}
                  <div className="mb-1 dashboard-header">
                    {randomTitle}
                  </div>
                  <div className="dashboard-subheader">
                    {randomSubtitle}
                  </div>
                </Col>
              </Row>
              <ul className="nav nav-tabs dashboard-tabs mt-4 mb-4">
                <li className={`nav-item ${isActiveClass(dashboardType)}`}>
                  <a
                    className={`nav-link d-flex align-items-center justify-content-center ${isActiveClass(dashboardType)}`}
                    aria-current="page"
                    href={tabPath({ currentLocale, useDemoData, location })}
                  >
                    <img
                      src={isActive(dashboardType) ? DashboardSquareWhiteImage : DashboardSquareBlackImage}
                      width={18}
                    />
                    <div className="ms-1">Main</div>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link d-flex align-items-center justify-content-center ${isActiveClass(dashboardType, 'requests_updated_recently')}`}
                    aria-current="page"
                    href={tabPath({ currentLocale, dashboard: 'requests_updated_recently', useDemoData, location })}
                  >
                    <img
                      src={isActive(dashboardType, 'requests_updated_recently') ? ChartHistogramWhiteImage : ChartHistogramBlackImage}
                      width={18}
                    />
                    <div className="ms-1">Requests updated recently</div>
                  </a>
                </li>
              </ul>
              {/*
                <div className="d-flex align-items-center mb-4">
                  <div className="watchers-label">Only requests watched by me or assigned to my team(s)</div>
                  <div className="d-flex align-items-center watched-by-me-switch form-check form-switch me-1">
                    <input
                      className="watchers-switch form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      onChange={this.handleUpdateToggleWatchState}
                    />
                  </div>
                </div>
              */}
              <DashboardWidgetsWrapper
                currentLocale={currentLocale}
                workspaceId={workspaceId}
                defaultChannelId={defaultChannelId}
                currentUser={currentUser}
                isCurrentUserAccountAdmin={isCurrentUserAccountAdmin}
                isEducationOrganizationPersona={isEducationOrganizationPersona}
                key={workspaceId}
                openModal={openModal}
                handleGeneralUpgradePlanModal={this.handleGeneralUpgradePlanModal}
                useDemoData={useDemoData}
                dashboardType={dashboardType}
              />
            </Container>
          ) : (
            <Container fluid className="dashboard-widgets vh-100 py-4 d-flex justify-content-center mt-0">
              <div className="shadow explore-our-demo-dashboard-container d-flex flex-column justify-content-center align-items-center">
                <div className="image-container mb-4">
                  <img src={DevicesImage} width={160} />
                </div>
                <div className="header">Explore Our Activation Guide & Demo Dashboard</div>
                <div className="subheader text-center pt-3 mb-3">
                  See what your dashboard will look like once you start using
                  our service. Get a firsthand experience of how we enable
                  customer success and sales to work seamlessly with delivery
                  teams.
                </div>
                <div className="d-flex">
                  <div
                    className="activation-wizard-button text-center mt-4 px-4 py-3"
                    onClick={this.handleActivationWizardModal}
                  >
                    Activation Guide
                  </div>
                  <a
                    href={`/#/${currentLocale}/dashboard-onboarding-demo?${queryString}`}
                    target="_blank"
                    className="ms-4 view-demo-dashboard-button mt-4 px-4 py-3"
                  >
                    View Demo Dashboard
                  </a>
                </div>
              </div>
            </Container>
          )}
        </div>
        <SlidingPane
          className='about-page-drawer dashboard'
          overlayClassName='sliding-pane-overlay'
          isOpen={this.state.isAboutPageDrawerOpen}
          title={'Pro Tip'}
          width='40%'
          subtitle=''
          onRequestClose={this.onOpenAboutPagerDrawerClose}>
            <AboutDashboardPageDrawer
              currentLocale={currentLocale}
              isCurrentUserAccountAdmin={isCurrentUserAccountAdmin}
              currentUser={currentUser}
            />
        </SlidingPane>
      </div>
    );
  }
}

export default withRouter(HomeDashboard);
