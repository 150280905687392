import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { toast } from 'react-toastify';

import Button from '../../../components/common/Button';
import ConfirmDeleteMessage from '../../../components/common/ConfirmDeleteMessage';
import EditWorkspaceMemberPane from '../../../components/pane/EditWorkspaceMemberPane';
import InviteWorkspaceMembersFilterForm from '../../../components/pane/InviteWorkspaceMembersFilterForm';
import { removeWorkspaceMember, getWorkspaceCsv } from '../../../services/workspace';
import { sleep, toastifyConfiguration } from '../../../components/util/helpers';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPaneOpen: false,
      isPaneOpenLeft: false,
      selectedWorkspaceMember: {},
      workspace: props.organizationWorkspace,
      isCsvActionInProgress: false,
    };
    this.handleOnClickEditWorkspaceMember = this.handleOnClickEditWorkspaceMember.bind(this);
    this.handleOnClickRemoveWorkspaceMember = this.handleOnClickRemoveWorkspaceMember.bind(this);
    this.onEditWorkspaceMemberPaneClose = this.onEditWorkspaceMemberPaneClose.bind(this);
    this.handleCsvExport = this.handleCsvExport.bind(this);
    this.handleConfirmRemoval = this.handleConfirmRemoval.bind(this);
  }

  componentDidMount() {
    const { workspaceId } = this.props;
    if (workspaceId) {
      this.props.fetchWorkspace({ workspaceId });
    }
    Modal.setAppElement(this.el);
  }

  handleOnClickEditWorkspaceMember = user => {
    this.setState({ isPaneOpen: true, selectedWorkspaceMember: user });
  }

  handleConfirmRemoval = async (user, event) => {
    event && event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmDeleteMessage
            handleRemoveItem={this.handleOnClickRemoveWorkspaceMember}
            subtitle="You want to remove this workspace member?"
            confirmButtonText="Yes, remove it!"
            itemToRemove={user}
            event={event}
            onClose={onClose}
          />
        );
      }
    });
  }

  handleOnClickRemoveWorkspaceMember = async (user, event) => {
    event.preventDefault();
    const { currentUser, workspaceId } = this.props;
    const response = await removeWorkspaceMember({
      organization_id: currentUser?.organization_id,
      workspace_id: workspaceId,
      workspace_member_id: user.id,
    });
    if (!(response || {}).error) {
      await this.props.fetchWorkspace({ workspaceId });
      toast.success('User removed successfully!', toastifyConfiguration({}));
    } else {
      toast.error('Error removing the user. Please try again.', toastifyConfiguration({}));
    }
  }

  onEditWorkspaceMemberPaneClose = () => {
    this.setState({ isPaneOpen: false });
  }

  handleCsvExport = async () => {
    event.preventDefault();
    const { currentUser, workspace } = this.props;
    const { id, name } = workspace;

    await getWorkspaceCsv({ id: id, workspace_name: name, organization_id: currentUser?.organization_id });
    await sleep(500);
    this.setState({ isCsvActionInProgress: false });
  }

  // handleWorkspaceSearch = event => {
  //   const input = event.target.value
  //   this.handleWorkspaceFilterFetch(input);
  // }
  //
  // handleWorkspaceFilterFetch = async input => {
  //   event && event.preventDefault();
  //   const { currentUser, workspaceId } = this.props;
  //   const filteredWorkspaces = await getWorkspace({ search: input, workspace_id: workspaceId, organization_id: currentUser?.organization_id });
  //   if (filteredWorkspace) {
  //     this.setState({ workspace: filteredWorkspace });
  //   }
  // }

  render() {
    let {
      currentUser,
      workspaceId,
      workspace,
      permittedWorkspaceRoles,
      currentLocale,
    } = this.props;

    let { isCsvActionInProgress, selectedWorkspaceMember } = this.state;
    let workspacesUsersCount = workspace.users.length;
    const columns = [{
      id: 'user',
      Header: 'user',
      sortable: false,
      accessor: u =>  u.first_name
        ? `${u.first_name} ${u.last_name} -- ${u.email}`
        : u.email
    }, {
      id: 'roles',
      Header: 'workspace role',
      sortable: false,
      accessor: u => <span className="workspace-roles">{(u.roles || []).map(r => r.name).join(', ')}</span>,
    }, {
      id: 'action',
      Header: 'Action',
      sortable: false,
      accessor: u => (
        <div>
          <span onClick={this.handleOnClickEditWorkspaceMember.bind(this, u)} className='edit-cell'>
            <i className="bi bi-pencil-square"></i>
          </span>
          <span onClick={this.handleConfirmRemoval.bind(this, u)} className='remove-cell'>
            <i className="bi bi-trash3"></i>
          </span>
        </div>
      )
    }]

    return (
      <div className="settings organization-workspace container-fluid px-5" ref={ref => this.el = ref}>
        <div className="content-header">
          <div className="title">Settings</div>
          <div className="subheader">
            <div className="page">Workspace access management</div>
          </div>
        </div>
        <div className="content-container mt-5">
          <div className="row mb-4 resource-metadata">
            <div className="col-md-4 column-width">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <div className="h5">Workspace users</div>
                    <div className="d-flex">
                      <i className="bi bi-person me-1"></i>
                      <div className="card-count">{workspacesUsersCount}</div>
                    </div>
                  </div>
                  <div>
                    Users with workspace access
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-actions-and-export d-flex justify-content-between">
            <div className="workspace-member-search">
              <InviteWorkspaceMembersFilterForm
                organizationId={currentUser?.organization_id}
                workspaceId={workspaceId}
              />
            </div>
            <div className="ms-2 button-actions">
              {isCsvActionInProgress ? (
                <button className="export export-csv workspace" type="button" disabled>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  <span>Exporting...</span>
                </button>
              ) : (
                <Button
                  handleClick={() => {
                    this.setState({ isCsvActionInProgress: true });
                    this.handleCsvExport();
                  }}
                  label="Export CSV"
                  classes="export export-csv"
                />
              )}
            </div>
          </div>
          <div className="container-fluid table-details-section">
            <ReactTable
              data={workspace.users}
              columns={columns}
            />
            <SlidingPane
              className='add-workspace-pane'
              overlayClassName='sliding-pane-overlay'
              isOpen={ this.state.isPaneOpen }
              title={'Edit Workspace Member Access Level'}
              width='60%'
              subtitle=''
              onRequestClose={this.onEditWorkspaceMemberPaneClose}>
                <EditWorkspaceMemberPane
                  workspaceId={workspaceId}
                  currentUser={currentUser}
                  onEditWorkspaceMemberPaneClose={this.onEditWorkspaceMemberPaneClose}
                  permittedWorkspaceRoles={permittedWorkspaceRoles}
                  selectedWorkspaceMember={selectedWorkspaceMember}
                />
            </SlidingPane>
          </div>
        </div>
      </div>
    );
  }
}

Content.propTypes = {};

export default Content;
