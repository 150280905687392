import React, { Fragment } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import withRouter from '../../../util/with_router';

import RequestsStatusByDates from '../widgets/RequestsStatusByDates';

class RequestsUpdatedRecentlyDashboardWidgets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {

    const {
      currentLocale,
      currentUser,
      isCurrentUserAccountAdmin,
      defaultChannelId,
      handleGeneralUpgradePlanModal,
      openModal,
      useDemoData,
      workspaceId,
    } = this.props;

    return (
      <div className="primary-dashboard">
        <Row>
          <Col md={6}>
            <RequestsStatusByDates
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={`${workspaceId}-done-since-today`}
              periodRange={'since_today'}
              status="done"
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
            />
          </Col>
          <Col md={6}>
            <RequestsStatusByDates
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={`${workspaceId}-done-since-last-30-days`}
              periodRange={'since_last_30_days'}
              status="in_progress"
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <RequestsStatusByDates
              currentLocale={currentLocale}
              workspaceId={workspaceId}
              defaultChannelId={defaultChannelId}
              currentUser={currentUser}
              key={`${workspaceId}-done-last-7-today`}
              periodRange={'since_last_seven_days'}
              status="testing"
              openModal={openModal}
              handleGeneralUpgradePlanModal={handleGeneralUpgradePlanModal}
              useDemoData={useDemoData}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(RequestsUpdatedRecentlyDashboardWidgets);
