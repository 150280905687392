import {
  RECEIVE_ORGANIZATION_USERS,
  RECEIVE_ORGANIZATION_TEAMS,
  RECEIVE_ORGANIZATION_TEAM,
  RECEIVE_ORGANIZATION_WORKSPACES,
  RECEIVE_ORGANIZATION_WORKSPACE,
  RECEIVE_ORGANIZATION_TAGS_BY_TYPE,
  RECEIVE_ORGANIZATION_CUSTOMER_TAGS,
  RECEIVE_ORGANIZATION_REQUESTS,
  RECEIVE_ORGANIZATION_USER_MENTIONS,
  RECEIVE_ORGANIZATION_WORKSPACE_WEBHOOKS,
  RECEIVE_ORGANIZATION_INVITATIONS,
} from '../actions/organization_actions';

const organizationReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_ORGANIZATION_USERS:
      return {
        ...state,
        users: action.organizationUsers.entries,
        pages: action.organizationUsers.total_pages,
        usersCount: action.organizationUsers.total_count,
        adminCount: action.organizationUsers.admin_count,
        workspaceManagerCount: action.organizationUsers.workspace_manager_count,
      };
    case RECEIVE_ORGANIZATION_TEAMS:
      return {
        ...state,
        teams: action.organizationTeams.entries,
        pages: action.organizationTeams.total_pages,
        teamsCount: action.organizationTeams.total_count,
      };
    case RECEIVE_ORGANIZATION_TEAM:
      return {
        ...state,
        team: action.organizationTeam,
      };
    case RECEIVE_ORGANIZATION_WORKSPACES:
      return {
        ...state,
        workspaces: action.organizationWorkspaces.entries,
        pages: action.organizationWorkspaces.total_pages,
        workspacesCount: action.organizationWorkspaces.total_count,
      };
    case RECEIVE_ORGANIZATION_WORKSPACE:
      return {
        ...state,
        workspace: action.organizationWorkspace,
      };
    case RECEIVE_ORGANIZATION_TAGS_BY_TYPE:
      return {
        ...state,
        tags: {
          ...state.tags,
          [action.tagType]: action.organizationTags,
        }
      };
    case RECEIVE_ORGANIZATION_CUSTOMER_TAGS:
      return {
        ...state,
        customerTags: action.organizationCustomerTags.entries,
        pages: action.organizationCustomerTags.total_pages,
        customerTagsCount: action.organizationCustomerTags.total_count,
      };
    case RECEIVE_ORGANIZATION_REQUESTS:
      return {
        ...state,
        requests: action.organizationRequests.entries,
        pages: action.organizationRequests.total_pages,
        requestsCount: action.organizationRequests.total_count,
      };
    case RECEIVE_ORGANIZATION_USER_MENTIONS:
      return {
        ...state,
        mentions: action.organizationUserMentions.entries,
      };
    case RECEIVE_ORGANIZATION_WORKSPACE_WEBHOOKS:
      return {
        ...state,
        webhooks: action.organizationWebhooks.entries,
        pages: action.organizationWebhooks.total_pages,
        webhooksCount: action.organizationWebhooks.total_count,
      };
    case RECEIVE_ORGANIZATION_INVITATIONS:
      return {
        ...state,
        invitations: {
          entries: action.invitations.entries,
          pages: action.invitations.total_pages,
          count: action.invitations.total_count,
        },
      };
    default:
      return state;
  };
};

export default organizationReducer;
